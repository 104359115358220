import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header'

import Home from './pages/Home'
import Upload from './pages/Upload'
import Posts from './pages/admin/Posts'

const App = () => {
  return (
    <Router>
      <Header />
      <Routes>
        <Route exact path='/admin/posts' element={<Posts/>} />
        <Route exact path='/upload' element={<Upload/>} />
        <Route exact path='/' element={<Home/>} />
      </Routes>
    </Router>
  );
}

export default App;
