//import { useEffect } from 'react';
//import FollowersColumn from '../../components/FollowersColumn'

const Posts = () => {
  
  const handleSubmit = async (e) => {
    e.preventDefault()

  // const data =  {
  //     id: id,
  //     name: name,
  //     username: username,
  //     avatar: avatar,
  //     is_followed: false,
  //     video: video,
  //     caption: caption,
  //     likes: 0,
  //     comments: 0,
  //     timestamp: timestamp,
  //     button_visible: false  
  //   }

  //   axios.post('/.netlify/functions/add', data)
  //   .then((response) => {
  //     console.log(response)
  //   })
  //   .catch((err) => {
  //     console.error(err)
  //   })
    console.log('Submit!')
  }

  return (
    <div className="container">
        <form name="test" onSubmit={handleSubmit}>
            <label>Enter Post ID:</label><br/>
            <input type="text" id="postid" name="postid"></input>
            <button type="submit">Submit</button>
        </form>
    </div>
  );
}

export default Posts;