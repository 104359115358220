
const Upload = () => {
    return (
      <div>
          <h1>Upload</h1>
      </div>
    );
  }
  
  export default Upload;
  